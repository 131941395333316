.loginmodal
    {
        width: 600px;
        position: absolute;
        top: 40%;
        left: 40%;
        margin-top: -5em;
        margin-left: -5em;
        z-index: 100;
        height: 300px;
        background-color: white;
    }

    .overlay
    {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background-color: rgba(0,0,0,0.5);
    }


    .FontFamily
    {
        font-family: "HelveticaNeueW02-67Cn",Helvetica,Arial,sans-serif !important;
        font-size: 14px !important;
    }

    .navigation > li.active > a, .navigation > li.active > a.level-opened
    {
        background: #AFB1B3 !important;
        border-color: #AFB1B3 !important;
        color: #fff;
    }

    .navigation > li > ul li a
    {
        padding: 11px 12px !important;
        display: block !important;
        font-size: 14px !important;
        color: Black !important;
    }

    .navigation > li > ul
    {
        background-color: #62D3FF !important;
        border: 1px solid #ddd !important;
        border-top: 0;
        color: black !important;
    }

    .navigation > li > a.level-opened
    {
        background: #D9D9D9 !important;
        border-color: #D9D9D9 !important;
        color: black !important;
    }

    .cursor
    {
        cursor: pointer;
    }

    .imagetext
    {
        width: 234px;
        height: 50px;
        border-radius: 14px;
        background-color: white;
        color: blue;
        font-weight: bolder;
    }

    .selectedTab
    {
        background-color: rgba(21, 139, 203, 0.75);
        color: White !important;
        text-align: left;
        font-size: x-large;
    }

    .show
    {
        background-color: RGB(150,197,225);
    }

    .alignment
    {
        background-color: rgba(58, 196, 58, 0.93);
        height: 62px;
        text-align: left;
        padding-top: 17px;
        color: White;
        border-left: 2px solid lightgrey;
        border-right: 2px solid lightgrey;
        border-bottom: 2px solid lightgrey;
    }

    .text
    {
        /* background-color: rgba(204, 223, 62, 0.59); */
        font-size: large;
        /* border-radius: 127px; */
        width: 85%;
        border-radius: 4px;
        /* right: 16px; */
        margin-left: 9px;
        height: 50px;
        /* text-align: center; */
        background-color: rgba(234, 216, 56, 0.47);
    }

    .class
    {
        visibility: hidden;
    }

    .parentcolor
    {
        background-color: RGB(150,197,225);
    }

    .tabdev
    {
        color: rgba(21, 139, 203, 0.75);
        text-decoration: none;
        width: 210px;
        height: 46px;
        border-radius: 5px;
        text-align: center;
        font-size: large;
        cursor: pointer;
        font-weight: bold;
        padding-top: 12px;
    }

    .visible
    {
        visibility: visible;
        opacity: 1;
        transition: opacity 2s linear;
    }

    .hidden
    {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s 2s, opacity 2s linear;
    }

    .navNext
    {
        padding-top: 12px;
        float: right;
        padding-bottom: 12px;
        padding-right: 24px;
    }

    .navPrev
    {
        float: left;
        padding-top: 12px;
        padding-left: 10px;
        padding-bottom: 12px;
    }
.topdivAmnt
{
    margin-left: 13px;
    width: 100%;
}

.div45withleft
{
    width: 45%;
    float: left;
}

.inputAmnt
{
    width: 90%;
    text-align: right;
    height: 44px
}

.spanPer
{
    width: 5%;
    margin-left: 0px;
    float: left;
    font-size: 1.8rem;
}

.div50left
{
    width: 50%;
    float: left;
}

.ddForDuretion
{
    color: black;
    border-radius: 10px;
    background-color: #CCFFFF;
    height: 44px;
    width: 70%;
    margin-left: 12px;
}

.padding-top-24
{
    padding-top:24px;
}
.addbtn
{
    background-position: left;
    border-radius: 6px;
    width: 286px;
    height: 58px;
    text-align: right;
    color: white;
    background-image: url("/assets/images/plusicon.png");
    background-repeat: no-repeat;
    background-size: 58px;
    background-color: green;
}
