/* Add a bit of separation between rows, specifically makt the input boxes
easier to read */
div.row {
  margin-bottom: 3px;
}

.navbar-custom {
  height: 72px;
  padding: 5px 9px;
  border: none;
  background-image: -ms-linear-gradient(right, #FFFFFF 0%, #FFCC00 100%);
  /* Mozilla Firefox */
  background-image: -moz-linear-gradient(right, #FFFFFF 0%, #FFCC00 100%);
  /* Opera */
  background-image: -o-linear-gradient(right, #FFFFFF 0%, #FFCC00 100%);
  /* Webkit (Safari/Chrome 10) */
  background-image: -webkit-gradient(linear, right top, left top, color-stop(0, #FFFFFF), color-stop(100, #FFCC00));
  /* Webkit (Chrome 11+) */
  background-image: -webkit-linear-gradient(right, #FFFFFF 0%, #FFCC00 100%);
  /* W3C Markup */
  background-image: linear-gradient(to left, #FFFFFF 0%, #FFCC00 100%);

  .navbar-right {
    margin: 0;
  }

  ul {
    float: left;
    margin-left: 20px;
    font-weight: bold;
    line-height: 1.25;

    li {
      padding-left: 9px;
    }

    a {
      color: rgb(0, 0, 0, 0.65);
    }

    li:first-child {
      font-size: 160%;
      padding-left: 0;
    }
  }
}
