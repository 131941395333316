.btn-well {
  background-color: #ddd;
  // border: 2px solid #aaa;
  border-radius: 9px;
  box-shadow: inset 3px 3px 5px rgba(255, 255, 255, 0.65), inset -2px -4px 7px rgba(16, 16, 16, 0.35);
  min-height: 20px;
  padding: 19px;
}
.btn-well .btn {
  font-size: 200%;
  font-weight: bold;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.25);
  margin-bottom: 15px;
}
