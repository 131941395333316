.tile {
  left:50%;
  margin: 5px 12px;
  -webkit-box-shadow: 4px 4px 4px 4px rgba(0,0,0,0.5);
  -moz-box-shadow: 4px 4px 4px 4px rgba(0,0,0,0.5);
  box-shadow: 4px 4px 4px 4px rgba(0,0,0,0.5);
}
// .overlay {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   z-index: 10;
//   background-color: rgba(0,0,0,0.5);
// }
.padding-top-12 {
  padding-top: 12px;
}
.padding-left-12 {
  padding-left: 12px;
}
// span {
//   font-family: 'Arial Regular', "Arial";
//   font-weight: 400;
//   font-style: normal;
//   font-size: 13px;
//   color: #333;
//   text-align: left;
//   line-height: normal;
// }
.text-none {
  text-decoration: none;
}
.welcomeTypebtn {
  border-radius: 15px;
  height: 65px;
  font-size: 2.6rem;
  width: 98%;
  background-color: RGB(59,119,219);
  color: white;
}
.welcomeTypeContainer {
  width: 48%;
  float: left;
  border: 8px groove grey;
  height: 150px;
  border-radius: 15px;
}
.headerbtns {
  min-width: 120px;
  height: 50px;
  background-color: lightpink;
  border-radius: 10px;
  font-size: medium;
  font-weight: bold;
}
