/**
 *  Do not remove this comments bellow. It's the markers used by wiredep to inject
 *  less dependencies when defined in the bower.json of your dependencies
 */
// bower:less
// endbower

/**
 *  If you want to override some bootstrap variables, you have to change values here.
 *  The list of variables are listed here bower_components/bootstrap/less/variables.less
 */

@import "../../bower_components/bootstrap/less/bootstrap.less";
@icon-font-path: "../bower_components/bootstrap/fonts/";
// @icon-font-path: "../../bower_components/bootstrap/fonts/";
@import "../../bower_components/fontawesome/less/font-awesome.less";
@fa-font-path: "../bower_components/fontawesome/fonts";
 // @fa-font-path: "../../bower_components/fontawesome/fonts";

 // override the header margins
 h4, .h4, h5, .h5, h6, .h6 {
    margin-bottom: 0;
    margin-top: 0;
}

/**
 *  Do not remove this comments bellow. It's the markers used by gulp-inject to inject
 *  all your less files automatically
 */
// injector
// endinjector
