form.calculator-form {
  /* Everytime we hit a list-group, reset the counter.*/
  counter-reset: part line;
  /* provides the padding that lines up the calculated fields witht the inputs */
  p.form-control-static {
    padding-right: 13px;
  }

  div.panel.calculation-segment .panel-heading h4 {
    counter-increment: part;
  }

  div.panel.calculation-segment .panel-heading h4:before {
    content: "Part " counter(part, upper-roman) ". ";
  }
  /* Note: the only way this selector can find the correct elements is to look at
  the class on the label.  The "6" column is the large numbered lines.  The
  "offset" is the indented one. */
  div.line-item {
    counter-increment: line;
    counter-reset: sub-line;
  }

  div.line-item > label:before {
    content: counter(line) ". ";
  }

  div.line-sub-item > label > small:before {
    counter-increment: sub-line;
    content: counter(line) counter(sub-line, lower-alpha) " ";
  }
  /*This controls the number in line 7 so that it matches the list (re)numbering
it's a bit of a hack*/
  span.ref {
    &:before {
      counter-increment: line -1;
      content: counter(line);
    }

    &:after {
      content: "";
      counter-increment: line;
    }
  }

  div.row-flex {
    display: flex;

    div[class*="col-"][class~="push-down"] {
      margin-top: auto;
    }
  }
}

.bootstrap-touchspin {
  .input-group-btn-vertical {
    position: relative;
    white-space: nowrap;
    width: 1%;
    vertical-align: middle;
    display: table-cell;
  }

  .input-group-btn-vertical > .btn {
    display: block;
    float: none;
    width: 100%;
    max-width: 100%;
    padding: 8px 10px;
    margin-left: -1px;
    position: relative;
  }

  .input-group-btn-vertical .bootstrap-touchspin-up {
    border-radius: 0;
    border-top-right-radius: 4px;
  }

  .input-group-btn-vertical .bootstrap-touchspin-down {
    margin-top: -2px;
    border-radius: 0;
    border-bottom-right-radius: 4px;
  }

  .input-group-btn-vertical i {
    position: absolute;
    top: 3px;
    left: 5px;
    font-size: 9px;
    font-weight: normal;
  }
}
