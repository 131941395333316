@import (reference) "../../../bower_components/bootstrap/less/mixins.less";
// remove the background color from the container
.modal-default .modal-content,
.modal-primary .modal-content,
.modal-success .modal-content,
.modal-info .modal-content,
.modal-warning .modal-content,
.modal-danger .modal-content {
  background-color: transparent;
}

// apply the border radius to the top and bottom
.modal-default .modal-header,
.modal-primary .modal-header,
.modal-success .modal-header,
.modal-info .modal-header,
.modal-warning .modal-header,
.modal-danger .modal-header  {
  .border-top-radius(@border-radius-large);
}
.modal-default .modal-footer,
.modal-primary .modal-footer,
.modal-success .modal-footer,
.modal-info .modal-footer,
.modal-warning .modal-footer,
.modal-danger .modal-footer {
  .border-bottom-radius(@border-radius-large);
  background-color: @modal-content-bg;
}
.modal-default .modal-body,
.modal-primary .modal-body,
.modal-success .modal-body,
.modal-info .modal-body,
.modal-warning .modal-body,
.modal-danger .modal-body {
  background-color: @modal-content-bg;
}

.modal-variant(@color; @background; @border) {
  .modal-header  {
    color: @color;
    background-color: @background;
    border-color: @border;
  }
}

// build modals based on button defaults
.modal-default {
  .modal-variant(@btn-default-color; @btn-default-bg; @btn-default-border);
}
.modal-primary {
  .modal-variant(@btn-primary-color; @btn-primary-bg; @btn-primary-border);
}
// Success appears as green
.modal-success {
  .modal-variant(@btn-success-color; @btn-success-bg; @btn-success-border);
}
// Info appears as blue-green
.modal-info {
  .modal-variant(@btn-info-color; @btn-info-bg; @btn-info-border);
}
// Warning appears as orange
.modal-warning {
  .modal-variant(@btn-warning-color; @btn-warning-bg; @btn-warning-border);
}
// Danger and error appear as red
.modal-danger {
  .modal-variant(@btn-danger-color; @btn-danger-bg; @btn-danger-border);
}
